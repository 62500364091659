<template>
  <div class="qgroup-form display--flex">
    <div class="card qgroup-form__staff">
      <div class="card-header card-header-divider">Прохождение</div>
      <div class="card-body">
        <form action="#" @submit.prevent="submitPassing" novalidate>
          <div class="qgroup-form__inputs display--flex justify-content-center">
            <div class="qgroup-form__col1">
              <custom-select-2
                label="Статус"
                name="status"
                v-model="form.status"
                :options="statusOptions"
              />
              <custom-select-2
                label="Сотрудник"
                name="user_id"
                v-model="form.user_id"
                :settings="usersAjax"
              />
              <custom-input
                label="id сотрудника"
                name="user_id"
                v-model="form.user_id"
              />
            </div>
          </div>
          <div
            class="
              qgroup-form__btns
              mt-25
              mb-10
              display--flex
              justify-content-end
            "
          >
            <button type="submit" class="btn btn-accent mr-10">
              Сохранить
            </button>
            <router-link to="/test/passing" class="btn btn-w"
              >Назад</router-link
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import useErrors from "@/mixins/useErrors";
import { reactive, ref } from "@vue/reactivity";
import CustomSelect2 from "@/components/Forms/Fields/CustomSelect2.vue";
import CustomInput from "@/components/Forms/Fields/CustomInput.vue";
import standartError from "@/mixins/standartError";
import { useRouter } from "vue-router";
import useAjaxSelect from "@/mixins/useAjaxSelect";

export default {
  components: { CustomSelect2, CustomInput },
  name: "passing-create-form",
  setup() {
    const store = useStore(),
      { setErrors, clearCustomErrors } = useErrors(),
      router = useRouter();
    const form = reactive({
      status: "",
      user_id: null,
    });
    const statusOptions = ref([
      { id: "new", text: "Новый" },
      { id: "payed", text: "Оплачен" },
      { id: "instruction", text: "Инструкция" },
      { id: "questionnaire", text: "Опросник" },
      { id: "in_progress", text: "В процессе" },
      { id: "finished", text: "Завершен" },
    ]);
    const { usersAjax } = useAjaxSelect();

    let submitPassing = () => {
      store
        .dispatch("test/createPassing", { ...form })
        .then(() => router.push("/test/passing"))
        .catch(standartError(setErrors, clearCustomErrors));
    };

    return {
      form,
      submitPassing,
      statusOptions,
      usersAjax,
    };
  },
};
</script>

<style lang="scss" scoped>
.qgroup-form {
  padding: 0 0 10px 0;
  display: flex;
  width: 85%;
  margin: auto;
  justify-content: center;
  flex-direction: column;

  .form-group {
    margin: 35px -15px;
  }

  .card-body {
    padding: 20px 50px;
  }

  &__col1 {
    flex: 1;
  }

  @media (max-width: 1360px) {
    width: 100%;
  }

  @media (max-width: 973px) {
    .form-group {
      margin: 15px -15px;
    }
  }
}
</style>